import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { ReactComponent as X } from 'assets/close.svg'

export default class Success extends Component {
  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }

  reloadPage = () => {
    if (typeof window !== 'undefined') {
      window.location.reload()
    }
  }

  render() {
    return (
      <Background>
        <Container>
          <CloseContainer onClick={this.reloadPage}>
            <Text>close</Text>
            <CloseIcon />
          </CloseContainer>
          <TopBar>Success!</TopBar>
          <Title>Thank you for your submission.</Title>
          <Text>
            A representative is reviewing your submission and will be getting
            back to you as soon as possible. You can read more about our
            services by clicking on the links below
          </Text>
          <Links>
            <NavLink to="/freight">HAUL</NavLink>
            <NavLink to="/fulfillment">SHIP</NavLink>
            <NavLink to="/warehousing">STORE</NavLink>
          </Links>
        </Container>
      </Background>
    )
  }
}

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
`
const Container = styled.div`
  width: 60%;
  font-size: 30pt;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: ${props => props.theme.primaryColor};
  background-color: white;
  border: 1px solid ${props => props.theme.primaryColor};
  padding-bottom: 60px;
  border-radius: 7px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  position: relative;

  @media (max-width: ${props => props.theme.xl}px) {
    width: 70%;
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 90%;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    margin-top: 100px;
  }
`
const CloseIcon = styled(X)`
  width: 20px;
  height: 20px;
  stroke: black;
`
const CloseContainer = styled.div`
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 25px;
  cursor: pointer;
`
const TopBar = styled.div`
  width: 90%;
  padding: 35px 0 20px;
  font-size: 30pt;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`
const Title = styled.h1`
  width: 100%;
  padding: 50px 0 20px;
  font-size: 20pt;
  text-align: center;
  letter-spacing: -1px;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.demi};
`
const Text = styled.p`
  width: 70%;
  margin: 0 auto;
  font-size: 13pt;
  text-align: center;
  letter-spacing: -1px;
  line-height: 18pt;
  color: ${props => props.theme.fontColor};
  font-weight: ${props => props.theme.book};
`
const Links = styled.div`
  width: 95%;
  margin: 30px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 70%;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 90%;
  }
`
const NavLink = styled(Link)`
  margin: 0 15px;
  padding: 25px 25px 20px;
  font-size: 12pt;
  text-align: center;
  letter-spacing: -1px;
  line-height: 0;
  color: ${props => props.theme.accentColor1};
  background-color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.demi};
  border: 2px solid ${props => props.theme.primaryColor};
  border-radius: 5px;
  transition-duration: 0.5s;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 30%;
    margin: 15px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 40%;
    margin: 15px;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    width: 60%;
    margin: 10px 25px;
  }

  &:hover {
    color: ${props => props.theme.primaryColor};
    background-color: ${props => props.theme.accentColor1};
  }
`
