import React, { Component } from 'react'
import styled from 'styled-components'
import Form from './Form'
import Success from './Success'

export default class Contact extends Component {
  state = { submitted: false }

  onSubmitted = () => this.setState({ submitted: true })

  render() {
    const { submitted } = this.state

    const { title } = this.props
    return (
      <Section>
        {submitted ? (
          <Success />
        ) : (
          <Container>
            <Title>{title}</Title>
            <Form onSubmitted={this.onSubmitted} />
          </Container>
        )}
      </Section>
    )
  }
}
const Section = styled.section`
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 50px 0;
  padding: 40px 50px;
  background-color: white;
  border-radius: 5px;
  width: 60%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 80%;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    width: 100%;
    box-shadow: none;
    margin: 0;
  }
`
const Title = styled.h3`
  padding: 10px 0;
  font-size: 18pt;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: ${props => props.theme.phone}px) {
    text-align: center;
  }
`
