import React from 'react'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import Yup from 'yup'

export default ({ onSubmitted }) => (
  <Formik
    initialValues={{
      email: '',
      name: '',
      phone: '',
      company: '',
      message: '',
      freight: false,
      warehousing: false,
      fulfillment: false,
      partnerCarrier: false,
      other: false
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email('Please enter a valid email address.')
        .required('Please provide an email.'),
      name: Yup.string().required('Please enter your name.'),
      phone: Yup.string().required('Please enter your phone number.'),
      company: Yup.string().required(
        'Please enter your company or product name'
      ),
      message: Yup.string().required(
        'Let us know what it is we can help you with today.'
      ),
      // modes
      freight: Yup.bool(),
      warehousing: Yup.bool(),
      fulfillment: Yup.bool(),
      partnerCarrier: Yup.bool(),
      other: Yup.bool()
    })}
    onSubmit={(values, { setSubmitting }) => {
      fetch('/api/contact', {
        method: 'POST',
        body: JSON.stringify(values)
      })
        .then(res => res.json())
        .then(response => {
          setSubmitting(false)
          onSubmitted && onSubmitted()
        })
        .catch(error => {
          setSubmitting(false)
          alert('Unexpected server error. Please try again.')
        })
    }}
    render={({ values, errors, touched, isSubmitting }) => (
      <FormContainer>
        <InputGroup>
          <InputContainer>
            <Input name="email" placeholder="Email Address" />
            <ErrorMessage>{touched.email && errors.email}</ErrorMessage>
          </InputContainer>
          <InputContainer>
            <Input name="name" placeholder="Name [first, last]" />
            <ErrorMessage>{touched.name && errors.name}</ErrorMessage>
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <Input name="phone" placeholder="Phone Number" />
            <ErrorMessage>{touched.phone && errors.phone}</ErrorMessage>
          </InputContainer>
          <InputContainer>
            <Input name="company" placeholder="Company/Product" />
            <ErrorMessage>{touched.company && errors.company}</ErrorMessage>
          </InputContainer>
        </InputGroup>
        <Label>Subject of Inquiry</Label>
        <InputGroup>
          <Checkboxes>
            <CheckboxContainer>
              <Checkbox name="freight" />
              <CheckboxLabel>Freight</CheckboxLabel>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox name="warehousing" />
              <CheckboxLabel>Warehousing</CheckboxLabel>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox name="fulfillment" />
              <CheckboxLabel>Fulfillment</CheckboxLabel>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox name="partnerCarrier" />
              <CheckboxLabel>Partner Carrier</CheckboxLabel>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox name="other" />
              <CheckboxLabel>Other</CheckboxLabel>
            </CheckboxContainer>
          </Checkboxes>
        </InputGroup>
        <InputContainer textarea>
          <TextArea
            component="textarea"
            name="message"
            placeholder="Response"
          />
          <ErrorMessage>{touched.message && errors.message}</ErrorMessage>
        </InputContainer>
        <Button disabled={isSubmitting}>SUBMIT</Button>
      </FormContainer>
    )}
  />
)

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.desktop}px) {
    flex-direction: column;
  }
`
const InputContainer = styled.div`
  display: flex;
  margin: 0 0 8px;
  flex-direction: column;
  width: ${props => (props.textarea ? 100 : 47)}%;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 100%;
    margin: 0;
  }
`
const Input = styled(Field)`
  border: solid rgba(0, 0, 0, 0.3) 1px;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 10px 0;
  background-color: white;
  transition-duration: 0.5s;

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-style: italic;
  }

  &:focus {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border: solid ${props => props.theme.primaryColor} 1px;
    &::placeholder {
      color: transparent;
    }
  }
`
const TextArea = styled(Input)`
  resize: none;
  height: 200px;
`
const ErrorMessage = styled.div`
  font-size: 10pt;
  height: 20px;
  color: red;
`
const Button = styled.button`
  all: unset;
  font-size: 16pt;
  padding: 20px 45px 15px;
  background-color: ${props => props.theme.accentColor1};
  font-weight: ${props => props.theme.demi};
  color: ${props => props.theme.primaryColor};
  align-self: center;
  cursor: pointer;
  border-radius: 5px;
  transition-duration: 0.5s;
  border: 2px solid ${props => props.theme.primaryColor};
  margin-top: 8px;

  &:hover {
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.accentColor1};
  }
`
const Label = styled.label`
  font-weight: ${props => props.theme.demi};
  font-size: 14pt;
`
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px;
  position: relative;
`
const CheckboxLabel = styled.label`
  font-weight: ${props => props.theme.book};
  font-size: 14pt;
  color: rgba(0, 0, 0, 0.3);
  font-style: italic;
`
const Checkbox = styled(Field).attrs({
  type: 'checkbox'
})`
  margin-right: 5px;
  width: 25px;
  height: 25px;
  background: white;
  cursor: pointer;
  padding: 3px;
`
const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${props => props.theme.tablet}px) {
    justify-content: center;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`
