import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import Media from 'react-media'
import { TweenMax, TimelineMax } from 'gsap'
import { Link } from 'react-scroll'

import { ReactComponent as Arrow } from 'assets/arrow.svg'

import GatsbyImageLoader from 'components/GatsbyImageLoader'

export default class Header extends Component {
  componentDidMount = () => {
    animate.animateArrow(this.arrowRef)
  }

  render() {
    const { images, title, subtitle, text, anchor } = this.props
    return (
      <Fragment>
        <Media query="(max-width: 500px)">
          {matches =>
            matches ? (
              <GatsbyImageLoader sizes={images.mobile.childImageSharp.sizes}>
                {({ src }) => <Background image={src} />}
              </GatsbyImageLoader>
            ) : (
              <GatsbyImageLoader sizes={images.desktop.childImageSharp.sizes}>
                {({ src }) => <Background image={src} />}
              </GatsbyImageLoader>
            )
          }
        </Media>
        <Container>
          <InfoContainer>
            <Heading>{title}</Heading>
            <SubHeading>{subtitle}</SubHeading>
            <Text>{text}</Text>
          </InfoContainer>
        </Container>
        <ArrowContainer to={anchor} offset={-70} smooth={true}>
          <div
            ref={x => {
              this.arrowRef = x
            }}
          >
            <DownArrow />
          </div>
        </ArrowContainer>
      </Fragment>
    )
  }
}

const animate = {
  /*********** animate scroll arrow ***********/
  animateArrow(arrowRef) {
    const tl = new TimelineMax({ repeat: -1 })
    let arrow = arrowRef.firstChild

    tl.add(TweenMax.to(arrow, 0.8, { marginBottom: 0 }))
    tl.add(TweenMax.to(arrow, 0.6, { opacity: 0 }))
    tl.add(TweenMax.to(arrow, 0.2, { marginBottom: 20 }))
    tl.add(TweenMax.to(arrow, 0.8, { opacity: 1 }))
  }
}

const Background = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(1) contrast(1) brightness(0.5);
  z-index: 1;
`
const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;

  @media (max-width: ${props => props.theme.tablet}px) {
    padding: 90px 40px 50px;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    padding: 90px 20px 50px;
  }
`
const ArrowContainer = styled(Link)`
  width: 100px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  left: calc(50% - 50px);
  cursor: pointer;
  z-index: 100;

  @media (max-width: 370px) {
    bottom: 10px;
  }
`
const DownArrow = styled(Arrow)`
  transform: scale(2);
  width: 22px;
  margin-bottom: 15px;
`
const InfoContainer = styled.div`
  height: 100%;
  padding: 90px 180px 90px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;

  @media (max-width: ${props => props.theme.xl}px) {
    padding: 40px 50px 40px 80px;
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 100%;
    padding: 90px 200px 90px 100px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    padding: 50px 30px;
  }
`
const Heading = styled.h1`
  font-size: 65pt;
  width: calc(100% + 180px);
  color: white;
  font-weight: ${props => props.theme.ultra};

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 100%;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 45pt;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    font-size: 30pt;
  }
`
const SubHeading = styled.h2`
  margin: 30px 0;
  font-size: 20pt;
  width: 100%;
  color: white;
  font-weight: ${props => props.theme.demi};

  @media (max-width: ${props => props.theme.phone}px) {
    font-size: 16pt;
  }
`

const Text = styled.p`
  margin: 0 0 20px;
  font-size: 18px;
  line-height: 20pt;
  width: 100%;
  color: white;
  font-weight: ${props => props.theme.demi};
`
