import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import SEO from 'components/SEO'
import FullWidthSection from 'components/FullWidthSection'
import Header from './Header'
import Contact from './Contact'
import NavBar from 'components/NavBar'
import Layout from 'components/Layout'

export const ContactPageTemplate = ({ header, section1, form }) => (
  <Fragment>
    <Header
      images={header.images}
      title={header.title}
      subtitle={header.subtitle}
      text={header.text}
      anchor={header.scrollAnchor}
    />
    <FullWidthSection
      title={section1.title}
      text={section1.text}
      anchor={header.scrollAnchor}
      color="white"
    />
    <Contact title={form.title} />
  </Fragment>
)

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, structuredData } = frontmatter
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
        structuredData={structuredData}
      />
      <Helmet title={seo.title} />
      <NavBar />
      <ContactPageTemplate
        header={frontmatter.header}
        section1={frontmatter.section1}
        form={frontmatter.form}
      />
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seo {
          title
          description
          url
        }
        structuredData {
          context
          type
          url
          name
          address
          telephone
          priceRange
          additionalProperty
          openingHours
          areaServed
          email
          legalName
          alternateName
          description
        }
        header {
          images {
            mobile {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            desktop {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          title
          subtitle
          text
          scrollAnchor
        }
        section1 {
          title
          text
        }
        form {
          title
        }
      }
    }
  }
`
